import { useEffect, useState } from "react";
import styles from "../styles/contatForm.module.css";
import Alert from "./Alert";

const APIURL = "https://javiers-api.vercel.app/"; //"http://localhost:5000/";
const regTel = /\d{10}/;
//eslint-disable-next-line
const regCorreo =
  /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;

const ContactForm = ({ isActive, onDisabled }) => {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorFullName, setErrorFullName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [comments, setComments] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertActive, setAlertActive] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");

  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-16469023736/FjG_CNXpi7EaEPi3ha09",
      value: 1.0,
      currency: "USD",
    });
  }, []);

  const validations = () => {
    let isValidated = true;

    if (fullname == "") {
      setErrorFullName("This box is required");
      isValidated = false;
    }
    if (email == "") {
      setErrorEmail("This box is required");
      isValidated = false;
    }
    if (phone == "") {
      setErrorPhone("This box is required");
      isValidated = false;
    }

    if (!regTel.test(phone)) {
      setErrorPhone("This box accepts only numbers and 10 characters");
      isValidated = false;
    }

    if (!regCorreo.test(email)) {
      setErrorEmail("Invalid email address");
      isValidated = false;
    }

    return isValidated;
  };

  const saveInfo = async () => {
    if (validations()) {
      console.log("enviar datos");

      const body = {
        nombre: fullname,
        correo: email,
        telefono: phone,
        observaciones: comments,
      };

      try {
        const data = await fetch(`${APIURL}saveInfoClient`, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(body),
        });

        if (!data.ok) {
          throw new Error("error al guardar al cliente");
        }

        const res = await data.json();
        setTypeAlert("succes");
        setAlertText("Your data have been sent, we will contact you soon.");
        setAlertActive(true);
        onDisabled();
      } catch (error) {
        setTypeAlert("err");
        setAlertText(
          "an error has occurred please try again if the error persists contact support"
        );
        setAlertActive(true);
      }
    }
  };

  const closeModal = () => setAlertActive(false);

  return (
    <>
      {alertActive ? (
        <Alert type={typeAlert} text={alertText} closeModal={closeModal} />
      ) : null}

      <div
        className={`${styles.container} ${
          isActive ? styles.active : styles.disabled
        }`}
      >
        <div className={styles.close} onClick={onDisabled}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M18 6l-12 12"></path>
            <path d="M6 6l12 12"></path>
          </svg>
        </div>
        <h3>Contacto</h3>
        <div>
          <label>Full Name*</label>
          <input
            style={{
              border:
                errorFullName !== "" ? "2px solid red" : "2px solid #adadad",
            }}
            onChange={(eve) => {
              setFullName(eve.target.value);
            }}
            onFocus={() => {
              setErrorFullName("");
            }}
            type="text"
            placeholder="Enter your full name"
          />
          <div className={styles.contError}>
            <span>{errorFullName}</span>
          </div>
        </div>
        <div>
          <label>Phone*</label>
          <input
            style={{
              border: errorPhone !== "" ? "2px solid red" : "2px solid #adadad",
            }}
            onChange={(eve) => {
              setPhone(eve.target.value);
            }}
            onFocus={() => {
              setErrorPhone("");
            }}
            type="text"
            placeholder="Enter your phone number"
          />
          <div className={styles.contError}>
            <span>{errorPhone}</span>
          </div>
        </div>
        <div>
          <label>Email*</label>
          <input
            style={{
              border: errorEmail !== "" ? "2px solid red" : "2px solid #adadad",
            }}
            onChange={(eve) => {
              setEmail(eve.target.value);
            }}
            onFocus={() => {
              setErrorEmail("");
            }}
            type="text"
            placeholder="Enter your email address"
          />
          <div className={styles.contError}>
            <span>{errorEmail}</span>
          </div>
        </div>
        <div>
          <label>Comments</label>
          <textarea
            onChange={(eve) => {
              setComments(eve.target.value);
            }}
            placeholder="Enter your comments"
          />
        </div>
        <div onClick={saveInfo} className={styles.contBtn}>
          <button>Enviar</button>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
